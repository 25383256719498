export const parseAllChats = (response) => {
    
    const chats = response;

    if (chats && Array.isArray(chats)) {
        let adminChat = null;
        const userChats = chats.filter(chat => {
            if (chat.attributes.name === 'Admin') {
                adminChat = {
                    id: Number(chat.id),
                    name: chat.attributes.name,
                    lastMessage: chat.attributes.last_message
                        ? chat.attributes.last_message.attributes.message
                        : 'No messages yet',
                    unreadCount: chat.attributes.unread_count || 0,
                    updatedAt: chat.attributes.last_message
                        ? chat.attributes.last_message.attributes.updated_at
                        : chat.attributes.raw_created_at,
                    imageUrl: chat.attributes.image_url || 'default_image_url.png',
                   createdAt: chat.attributes.created_at
                };
                return false;  
            }
            return true;
        });

       
        const sortedUserChats = userChats.map(chat => ({
            id: Number(chat.id),
            name: chat.attributes.name,
            lastMessage: chat.attributes.last_message
                ? chat.attributes.last_message.attributes.message
                : 'No messages yet',
            unreadCount: chat.attributes.unread_count || 0,
            updatedAt: chat.attributes.last_message
                ? chat.attributes.last_message.attributes.updated_at
                : chat.attributes.raw_updated_at,
            imageUrl: chat.attributes.image_url || 'default_image_url.png',
            createdAt: chat.attributes.created_at
        }))
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) 
        if (adminChat) {
            return [adminChat, ...sortedUserChats];
        }
        return sortedUserChats;
    }

    return [];
};
