import React, { useState, useEffect, useRef } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import searchIcon from '../../../public/images/search.png';
import MessagesItem from './MessagesItem';
import messagesIcon from '../../../public/images/image_message_icon.png';
import StartNewMessage from './StartNewMessage';
import { fetchChatList, fetchPrivateChat, sendMessage, markMessagesAsRead, msgHistory, fetchAccountsForChat } from '../../shared/services/chatApi';
import { parseAllChats } from '../../shared/utils/chatUtils';
import websocketService from '../../shared/services/webSocketService';
import LoadingSpinner from '../../shared/Loader';

function Messages() {
    const [userMessage, setUserMessage] = useState('');
    const [userSearch, setUserSearch] = useState('');
    const [selectedChat, setSelectedChat] = useState(null);
    const [chatListUser, setChatListUser] = useState([]);
    const [selectedChatId, setSelectedChatId] = useState();
    const [unreadCount, setUnreadCount] = useState(0);
    const [defaultChatSelected, setDefaultChatSelected] = useState(false);
    const [privateChatImg, setPrivateChatImg] = useState('');
    const [privateChatName, setUserPrivateChatName] = useState('');
    const [openNewMessage, setOpenNewMessage] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null); 
    const [isLoading, setIsLoading] = useState(true);
    const [chatHistory, setChatHistory] = useState(null);
    const [messagesDateTime, setMessagesDateTime] = useState('Today')
    const [debouncedValue, setDebouncedValue] = useState(userSearch);
    const [userType, setUserType ] = useState('');
    const [usersOnlineStatus, setUsersOnlineStatus] = useState({});
    const [accountsForChat, setAccountsForChat] = useState([])
    const [isSending, setIsSending] = useState(false)

    const messagesEndRef = useRef(null);
    const messagesContainerRef = useRef(null); 
    

    const loadChatList = async () => {
        try {
            const response = await fetchChatList();
            const parsedChats = parseAllChats(response);
            if (userSearch) {
                setChatListUser(parsedChats);
            } else {
                setChatListUser(parsedChats.slice(0,5));
            }
            const adminChat = parsedChats.find(chat => chat.name.toLowerCase() === 'admin'); 
            const totalUnreadCount = parsedChats.reduce((total, chat) => {
                return total + (chat.unreadCount || 0);
            }, 0);
            setUnreadCount(totalUnreadCount);
 
            if (!defaultChatSelected && adminChat) {
                handleSelectChat(adminChat);  
                setDefaultChatSelected(true);  
            } else if (!adminChat) {
                console.error("Admin chat not found", parsedChats);
            }
        } catch (error) {
            console.error('Error fetching chats:', error);
        } finally {
            setTimeout(()=>{
                setIsLoading(false)
            },[1000])
          }
    };

    useEffect(() => {
        setUserType(localStorage.getItem('user_type'))
        loadChatList();
        websocketService.connect(handleMessageReceived, updateOnlineStatus);
    
        return () => {
            websocketService.disconnect(); 
        };
    }, [selectedChatId]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(userSearch);
        }, 500); 
    
        return () => {
            clearTimeout(handler); 
        };
    }, [userSearch]);

    useEffect(() => {
        loadChatList(); 
    }, [debouncedValue]);
    
    const handleScroll = () => {
    
        if (messagesContainerRef.current) {
            const { scrollTop, scrollHeight } = messagesContainerRef.current;
            if (scrollTop === 0) {
                const previousScrollHeight = scrollHeight;
                loadMoreMessages(selectedChatId, currentPage + 1)
               
                .then(() => {
                    const newScrollHeight = messagesContainerRef.current.scrollHeight;
                    messagesContainerRef.current.scrollTop = newScrollHeight - previousScrollHeight;
                    
                });
            }
        }
    };

    useEffect(() => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (messagesContainerRef.current) {
                messagesContainerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [currentPage, selectedChat]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const classnameForUserChat = {
        buyer: 'col-md-12',
        seller: 'col-md-9'
    }

    const userInputHandler = (e) => {
        setUserMessage(e.target.value);
    };

    const updateOnlineStatus = (userName, isOnline) => {
        setUsersOnlineStatus((prevState) => ({
          ...prevState,
          [userName]: isOnline,
        }));
      };
    
    const userSearchHandler = (e) => {
        setUserSearch(e.target.value);
    };

    
    const handleSelectChat = (chat) => {
            setChatHistory(null)
            setSelectedChatId(chat.id);
            updatePrivateChat(chat.id);
            updateChatHistory(chat.id);
            markMessagesAsRead(chat.id);
            setPrivateChatImg(chat.imageUrl);
            setUserPrivateChatName(chat.name);     
    };

    const updateChatHistory = async (chatId)=>{
        setCurrentPage(1)
        try {
          const chatHis = await msgHistory(chatId, 1); 
          if (chatHis) {
            setChatHistory(chatHis.data.data[0].messages)
          }
        } catch (error) {
          console.error(error.message);
        }
      }

    const updatePrivateChat = async (chatId) => {
        try {
            const response = await fetchPrivateChat(chatId);
            
            if (response) {
                setSelectedChat(response.data);
                updateChatHistory(chatId)
                
            }
        } catch (error) {
            console.error(error.message);
        }
    };

      const loadMoreMessages = async (chatId, page) => {
        try {
          const res = await msgHistory(chatId, page);
          if (res.data && res.data.data && res.data.data.length > 0) {
            const newMessages = res.data.data[0].messages || [];
            const dateTime = res.data.data[0].datetime
            const meta = res.data.meta;
            setMessagesDateTime(dateTime)
            setChatHistory((prevMessages) => [...prevMessages,...newMessages]);
            setCurrentPage(meta.current_page);
            setTotalPages(meta.total_pages);
          } else {
            console.warn("No more messages to load or invalid response format.");
          }
        } catch (error) {
          console.error('Error loading more messages:', error);
        }
      };

    const handleMessageReceived = (message) => {
        const messageData = message?.message?.data?.data?.attributes;
        if (messageData) {
            selectedChatId === messageData.chat_id 
                ? updatePrivateChat(messageData.chat_id) && loadChatList()
                : loadChatList();
        } else {
            console.warn('Invalid WebSocket message structure');
        }
    };

    const sendMessageHandler =  async(chatId, userMessage) => {
        if (isSending) return;
        setIsSending(true);
        try {
            await sendMessage(chatId, userMessage); 
            setUserMessage('');
            markMessagesAsRead(chatId);
            updatePrivateChat(chatId);
            setCurrentPage(1)
            loadChatList();
            scrollToBottom();   
            console.log('handling', chatId)
        } catch (error) {
            console.error('Failed to send message:', error);
        }finally{
            setIsSending(false)
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && selectedChat && userMessage.trim()) {
          sendMessageHandler(selectedChat.id, userMessage);
        }
      };

    const closeNewMessage  = async (state) => {
        setOpenNewMessage(state);
        handleCloseChat()
    };

    const openNewMessageScreen = (state) => {
        setOpenNewMessage(state);
        loadAccForNewChat();
    };

    const loadAccForNewChat = async() => {
        const res  = await fetchAccountsForChat()
        setAccountsForChat(res)
        console.log('res------->', res)
    }   

    const handleCloseChat = () => {
        setSelectedChat(null)
        setSelectedChatId()
        setPrivateChatImg('')
        setUserPrivateChatName('')
        setCurrentPage(1)
        setTotalPages(null)
        setChatHistory(null)
    };

    const renderChatSection = () => {
        let lastMessageDate = null;
    
        const getFormattedDate = (date) => {
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
    
            const messageDate = new Date(date);
            if (messageDate.toDateString() === today.toDateString()) {
                return "Today";
            } else if (messageDate.toDateString() === yesterday.toDateString()) {
                return "Yesterday";
            } else {
                return messageDate.toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                });
            }
        };
        
        return (
            <div data-testid="chat-section" className="messagesContainer privateMessages">
                {selectedChat && (
                    <>
                        <div className="userSection" data-testid="selected-chat">
                            <div onClick={() => handleCloseChat()}>
                                <img src="/images/backArrow.jpg" alt="Back_Arrow" />
                            </div>
                            <div className={`${usersOnlineStatus[selectedChat.attributes.name] ? 'dashboardChatAvatar' : ''}` }>
                                <img className='dashBoardImg' src={privateChatImg} alt="user avatar" />
                            </div>
                            <p data-testid="chat-user-name">{privateChatName}</p>
                        </div>
                        <div className="chatSection">
                            <div className="messages" data-testid="message-list" ref={messagesContainerRef}>
                                {chatHistory 
                                    ? chatHistory.slice(0).reverse().map((msg, index) => {
                                        const messageDate = new Date(msg.attributes.raw_created_at).toLocaleDateString();
                                        const isNewDay = messageDate !== lastMessageDate;
                                        lastMessageDate = messageDate;
        
                                        return (
                                            <React.Fragment key={index}>
                                                {isNewDay && (
                                                    <div className="divider">
                                                        <span>{getFormattedDate(msg.attributes.raw_created_at)}</span>
                                                    </div>
                                                )}
                                                <div className={`message ${msg.attributes.is_sender ? 'sent' : 'received'}`} data-testid={`chat-message-${index}`}>
                                                    <p>{msg.attributes?.message}</p>
                                                    <span className="timestamp">
                                                        {new Date(msg.attributes.raw_created_at).toLocaleTimeString('en-US', {
                                                            hour: 'numeric',
                                                            minute: 'numeric',
                                                            hour12: true
                                                        }).toLowerCase()}
                                                    </span>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })
                                    : <>No messages found</>
                                }   
                                <div ref={messagesEndRef}></div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    };
    
    
        
    const renderInputSection = () => {
        return (
            <div className="inputSection" data-testid="input-section">
                <input
                    placeholder="Type your message..."
                    value={userMessage}
                    onChange={userInputHandler}
                    data-testid="message-input"
                    onKeyDown={handleKeyDown}
                />
                <button 
                    className="chatButton" 
                    data-testid="send-button" 
                    onClick={() => sendMessageHandler(selectedChatId, userMessage)}> 
                    <p>Send</p>
                </button>
            </div>
        );
    };

    return (
        <div data-testid="messages-test-id">
            <Header />
            <div className="blank"></div>
            <div className="container py-4">
                <div className="row">
                    {userType === 'seller'
                        ? 
                        <div className="col-md-3">
                            <Sidebar id="6" />
                        </div>
                        : <></>
                    }
                    <div className={userType === 'buyer' ? classnameForUserChat.buyer : classnameForUserChat.seller}>
                        <div className="rightSide">
                            <div className="breadcrumbs">
                                <span className="active-page" data-testid="messages-header">Messages</span>
                            </div>
                            { isLoading 
                                ? <LoadingSpinner /> 
                                : <div className="row">
                                <div className="col-md-5 chatFlex">
                                    <div className="messagesContainer" data-testid="messages-container">
                                        <div className='messagesTitleWrap' data-testid="messages-title">
                                            <div className='messagesTitle underscore'>
                                                {unreadCount > 0 ? (
                                                        <p>Messages {`(${unreadCount})`}</p>
                                                    ):  <>  
                                                            <p>Messages</p>
                                                        </>
                                                }
                                                <img src={messagesIcon} alt="messages icon" />
                                            </div>
                                        </div>
                                        <div className='inputFieldWrap'>
                                            <div className="inputField">
                                                <img src={searchIcon} alt="search" />
                                                <input
                                                    placeholder="Search"
                                                    onChange={userSearchHandler}
                                                    data-testid="search-input"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                        <ul className="messagesList" data-testid="messages-list">
                                            {chatListUser.length > 0 ? (
                                                chatListUser.map((chat) => (
                                                <li
                                                    key={chat.id}
                                                    className={`messageItem ${selectedChatId === chat.id ? 'active' : ''} underscore`}
                                                    data-testid={`message-item-${chat.id}`}  
                                                    onClick={()=>handleSelectChat(chat)}
                                                    
                                                >
                                                    <MessagesItem
                                                    username={chat.name}
                                                    img={chat.imageUrl}
                                                    isRead={chat.unreadCount}
                                                    usermessage={chat.lastMessage}
                                                    isActive={`${selectedChat?.id === chat.id || chat.unreadCount ? 'active' : ''}`}
                                                    isOnline={usersOnlineStatus[chat.name]}
                                                    createdAt={chat.updatedAt}
                                                    />
                                                </li>
                                                ))
                                            ) : (
                                                <p data-testid="no-messages">No messages found</p>  
                                            )}
                                        </ul>
                                        </div>
                                        <div className={userType === 'buyer' ? `newMessageBtnContainer containerBuyer` : `newMessageBtnContainer containerSeller` }>
                                            <button className="newMessage" data-testid="new-message-button" onClick={()=> { 
                                                openNewMessageScreen(true)
                                                handleCloseChat()
                                            }}> 
                                                <span>+</span>
                                                <p>Start new message</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-7 chatFlex">
                                {openNewMessage ? (
                                    <StartNewMessage onCloseNewMessage={closeNewMessage} accountsForChat={accountsForChat} onShowChat={handleSelectChat}/>
                                    ) : (
                                        <>
                                            {renderChatSection()}
                                            {renderInputSection()}
                                        </>
                                        )}
                                </div>
                            </div>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Messages;
