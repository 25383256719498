import React from "react"


function MessagesItem({username, usermessage, isActive, isOnline, img, isRead, createdAt}) {

    return (
        <>
            <div className="messagesItemContainer" data-testid="message-item">
                <div className={`${isOnline ? 'dashboardChatAvatar' : ''}`}>
                    <img className='dashBoardImg' src={`${img}`} />
                </div>
                <div className="messagesItemContent">
                    <div className="infoSection">
                        <p className="messagesUserName">
                            {username}
                        </p>
                        <div className={`messageTime ${isActive}`}>
                            <p className="timeStatus">
                                {createdAt}
                            </p>
                            
                        </div>
                    </div>
                        <div className="messageStatus">
                            <p className={`messagePreview ${isActive}`}>
                                {usermessage}
                            </p>
                            <div className={`${isRead ? 'readStatus' : ''}`}></div>
                        </div>
                </div>
            </div>
        </>
    )

}

export default MessagesItem